$(window).load(function () {

});

$(window).on('beforeunload', function () {

});


$(window).ready(function () {

	function init() {
		new WOW().init({
			offset: 100
		});
		$(".header").clone().appendTo("body").addClass('scrollheader').attr('id','');

		checkHeader();
		contact();
		$('#carousel').find('.carousel-item').first().addClass('active');
		$('li:has(ul)').addClass('submenu')
		$('main:has(.background)').addClass('background')
		$('.form-group').click(function () {
			$(this).find(' .help-block').fadeOut()

		});

		$('.popup-with-zoom-anim').magnificPopup({
			type: 'inline',

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: 'auto',

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in'
		});

		 $(function () {
		 	 var $win = $(window);
              $win.scroll(function () {
                 checkHeader();
             });
         });
        	};

	init();

	function checkHeader(){
 var $win = $(window);
		if ($win.scrollTop() < 140){
            $('.scrollheader').removeClass('visible')
        }
        else{
            $('.scrollheader').addClass('visible')
        }
	}


function contact(){
		$('#contactform form').on('submit', function(e){
			e.preventDefault();
			$('#contactform form .message').addClass('hidden');
			$('#contactform form').addClass('loading');
			$.post($('#contactform form').attr('action'),$('#contactform form').serialize(), function(response){

				$('#contactform form .data').removeClass('loading');
				console.log(response)
				if(response == 'true'){
					$('.success-message').removeClass('hidden');
					$('#contactform form').addClass('hidden');

					if($('html').attr('lang') == 'nl'){
						window.history.pushState('', '', '/nl/contact/bedankt');
                 		ga('send', 'pageview', '/nl/contact/bedankt');
                 		gtag('config', 'UA-24070541-45', {'page_path': '/nl/contact/bedankt'});
					}
				}else{
					$(' .error-message').removeClass('hidden');
				}
	        });
		});
	}


});
